import React, { Component } from 'react';
import {Switch} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {Route} from 'react-router-dom';
import {connect} from 'react-redux';


import * as actions from "./store/actions";

// import ProtectedRoute from './hoc/RouteWrapper/ProtectedRoute';
// import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import Spinner from './components/UI/Spinner/Spinner';

import './App.scss';



/**
 * CHECKED-2021
 *  TBD - Add version change against db
 */
class App extends Component {


    state = {
        initialized: true
    };



    // componentDidMount(){
    //
    // }









    render() {

        if(!this.state.initialized){
            return <Spinner/>;
        }

        return (
            <React.Fragment>

                <Switch>


                    {/*<Route path="/registration">*/}
                    {/*    <PublicRoutes />*/}
                    {/*</Route>*/}

                    {/*<ProtectedRoute key="/home" path="/home" component={PrivateRoutes} />*/}

                    <Route path="/">
                        <PublicRoutes />
                    </Route>

                </Switch>

            </React.Fragment>
        );
    }
}




const mapStateToProps = (state) => {
    return {
        user_id: state.security.user_id,
        role_id: state.security.role_id,
        username: state.security.username,
        firstname: state.security.firstname,
        lastname: state.security.lastname
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSecurityContext: (user) => {
            dispatch( actions.setSecurityContext(user) );
        },
        setSecurityContextRole: (role_id) => {
            dispatch( actions.setSecurityContextRole(role_id) );
        },
        clearSecurityContext: () => {
            dispatch( actions.clearSecurityContext() );
        },
        setSecurityContextLoginMessage: (login_message, login_status) => {
            dispatch( actions.setSecurityContextLoginMessage(login_message, login_status) );
        }
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
