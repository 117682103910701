import * as actionTypes from '../actions/actionTypes'

import {updateObject} from '../../utils/utility';
// import {RoleTypes} from "../../models/RoleTypes";


const initialState = {
    user_id:null,
    username:null,
    // role_id: RoleTypes.NONE,
    firstname:null,
    lastname:null,
    sales_team_role_type_array:null,
    login_message:null,
    login_status:0
};


const reducer = (state=initialState, action) => {

    switch(action.type){

        case actionTypes.SET_SECURITY_CONTEXT_ROLE:
            return updateObject(state, {
                role_id:action.role_id
            });

        case actionTypes.SET_SECURITY_LOGIN_MESSAGE:
            return updateObject(state, {
                login_message:action.login_message,
                login_status:action.login_status
            });


        case actionTypes.SET_SECURITY_CONTEXT:


            return updateObject(state, {
                user_id:action.user_id,
                username:action.username,
                role_id: action.role_id,
                firstname: action.firstname,
                lastname: action.lastname,
                sales_team_role_type_array: action.sales_team_role_type_array
            });

        case actionTypes.CLEAR_SECURITY_CONTEXT:
            return updateObject(state, {
                user_id:null,
                username:null,
                role_id: null,
                firstname:null,
                lastname:null,
                sales_team_role_type_array:null
            });

        default:
            return state;
    }
};

export default reducer;