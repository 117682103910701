

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
};


export const formDate_1 = (d) => {

    const date = d.getDate();
    const month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12.
    const year = d.getFullYear();
    return month + "/" + date + "/" + year;
};


/**
 * Format: adress1, address2, address3, city, state, zip
 */
export const format_Address_1 = (address1, address2, city, state, zip) => {

    let str = "";
    if(address1){
        str=address1;
    }

    if(address2){
        if(str){
            str = str+" "+address2;
        } else {
            str = address2;
        }
    }

    if(city){
        if(str){
            str = str+" "+city;
        } else {
            str = city;
        }
    }

    if(state){
        if(str){
            str = str+" "+state+ ((zip)?",":"");
        } else {
            str = state+((zip)?",":"");
        }
    }

    if(zip){
        if(str){
            str = str+" "+zip;
        } else {
            str = zip;
        }
    }

    return str;
};



export const formatName = (prefix, first, middle, last) => {

    let array = [];
    if(prefix)array.push(prefix);
    if(first)array.push(first);
    if(middle)array.push(middle);
    if(last)array.push(last);

    return array.join(' ');
}


export const formatName_2 = (prefix, first, middle, last, designation) => {

    let array = [];
    if(prefix)array.push(prefix);
    if(first)array.push(first);
    if(middle)array.push(middle);
    if(last)array.push(last);
    if(designation)array.push(designation);

    return array.join(' ');
}


export const formatDuration = (seconds, naValue) => {

    if(!seconds){
        return naValue;
    }

    let time = parseFloat(seconds);
    if(!time){
        return naValue;
    }


    //
    // Seconds++
    //
    if( time<60 ){
        let sec = Math.floor(time);
        return sec+" sec"; //+addPluralToTimeSegment(sec);
    }

    //
    // Minutes++
    //
    time = time/60;
    if(time<1.0){
        time=1;
    }
    if(time<60){
        let min = Math.ceil(time);  // Round up
        return min+" min"; //+addPluralToTimeSegment(min);
    }

    //
    // Hour++
    //
    time = time / 60;
    let hrs = Math.floor(time);
    let hrsLabel = hrs+"hr"; //+addPluralToTimeSegment(hrs);

    let minLabel = "";
    let min = Math.ceil(((time-hrs)*60));

    if(min>=1){
        minLabel = min+"m";
    }


    return hrsLabel+" "+ minLabel;
};




// const addPluralToTimeSegment = (time) => {
//     if(time<=1){
//         return "";
//     }
//
//     return "s";
// }

// export const buildPhoneLink = (phone, defaultValue) => {
//
//     phone = fixPhoneFormat_1(phone,null);
//
//     if(phone){
//         return '<a href="tel:'+phone+'">'+ phone+'</a>';
//     }
//     return defaultValue;
// };




export const isStringEmpty = (str) => {
    return str==null || str.length === 0 || !str.trim();
};




export const trimString = (str, defaultValue) => {

    if(!str || !str.trim){
        return defaultValue;
    }

    const newStr = str.trim();

    if(newStr.length===0){
        return defaultValue;
    }

    return newStr;
};

export const trimStringToNull = (str) => {
    return trimString(str,null)
};


export const trimStringToEmpty = (str) => {
    return trimString(str,"");
};



export const safeParseToInt = (str,defaultValue) => {

    const val = parseInt(str);
    if(isNaN(val)){
        return defaultValue;
    }

    return val;
};



export const getRandomValue_1 =() => {

    var array = new Uint32Array(1);
    return window.crypto.getRandomValues(array);
}


export const checkForUndefined = (obj) => {
    if(!obj){
        return null;
    }

    return obj;
}


