import React from 'react';

import classes from './Main.module.scss';

/**
 * CHECKED-2021
 */
const Main = (props) => {

    return (

        <div id="main" className={classes.main+" "+ (props.className?props.className:"")}>

            {props.children}

        </div>
    );
};


export default Main;