
//
// ** System **
//
export const SET_HAS_SYSTEM_ERROR = 'SET_HAS_SYSTEM_ERROR';


//
// ** Security **
//
export const SET_SECURITY_CONTEXT = 'SET_SECURITY_CONTEXT';
export const CLEAR_SECURITY_CONTEXT = 'CLEAR_SECURITY_CONTEXT';

export const SET_SECURITY_CONTEXT_ROLE = 'SET_SECURITY_CONTEXT_ROLE';
export const SET_SECURITY_LOGIN_MESSAGE = 'SET_SECURITY_LOGIN_MESSAGE';

export const SET_PLAYING_VIDEO = 'SET_PLAYING_VIDEO';


//
// ** Misc **
//
export const SET_PROFILE_IMAGE_UPDATED = 'SET_PROFILE_IMAGE_UPDATED';

