import React, { Component } from 'react';
import {Switch, Redirect} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {Suspense} from 'react';
import {connect} from 'react-redux';

import RouteWrapper from './hoc/RouteWrapper/RouteWrapper';
import PublicHeader from './components/Header/PublicHeader';
import Footer from './components/Footer/Footer';
import ErrorBoundary from './hoc/ErrorBoundary/ErrorBoundary';
import Spinner from './components/UI/Spinner/Spinner';


// Lazy Load...
const Survey = React.lazy(() => import('./pages/public/survey/Survey/Survey'));
const SurveyStatus = React.lazy(() => import('./pages/public/survey/SurveyStatus/SurveyStatus'));


class PublicRoutes extends Component {


    render() {

        return (
            <React.Fragment>

                <ErrorBoundary>
                    <Suspense fallback={<Spinner/>} maxDuration={1000}>

                        <PublicHeader/>
                        <Switch>

                            <RouteWrapper key="/survey/status" path="/survey/status" exact component={SurveyStatus} />

                            <RouteWrapper key="/survey" path="/survey" exact component={Survey} />

                            <Redirect to="/survey/status"/>

                        </Switch>

                        <Footer/>

                    </Suspense>
                </ErrorBoundary>

            </React.Fragment>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        user_id: state.security.user_id,
        role_id: state.security.role_id
    }
};

export default connect(mapStateToProps, null)(withRouter(PublicRoutes));
