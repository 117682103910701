

//
// Frontend Configuration File.
//
// Note: DO NOT PUT ANY CONFIDENTIAL DATA IN THIS CONFIG.
//       ITS EXPOSED TO THE PUBLIC.
//

const ENVIRONMENT_TYPES = {
    DEV_LOCAL:1,
    DEV_REMOTE:2,
    STAGE:3,
    PRODUCTION:4,
    WARNING__LOCAL_PRODUCTION:5
};


// For deploying to dev/qa this must be set to true.
const ENVIRONMENT = ENVIRONMENT_TYPES.PRODUCTION;




export const getSiteConfig = ()=>{
    if(ENVIRONMENT===ENVIRONMENT_TYPES.DEV_LOCAL){
        //
        // Development config.
        //
        return {

            APP_ENGINE_SURVEY_SERVICES_URL:"http://localhost:8097",

            // FIREBASE_STORAGE_URL:"https://firebasestorage.googleapis.com/v0/b/allergan-ami-d2-dev-6a857.appspot.com/o/",
            // RECAPCTHA_KEY:"6Ldtna0aAAAAAPB4YZZnFp6evOIUnoqCeI88j6US",

            GTM_CODE:"GTM-WRLV2CG",

            FIREBASE_CONFIG: {
                apiKey: "AIzaSyBrXdvxv2Mm0lG9ej2FEQof0qd4_CAzWAs",
                authDomain: "allergan-ami-survey-dev.firebaseapp.com",
                projectId: "allergan-ami-survey-dev",
                storageBucket: "allergan-ami-survey-dev.appspot.com",
                messagingSenderId: "1011904675572",
                appId: "1:1011904675572:web:9d8eb1ba2ca18c4e8c68a1",
                measurementId: "G-K00HLE1NTR"
            }

        }
    }

    if(ENVIRONMENT===ENVIRONMENT_TYPES.DEV_REMOTE) {
        //
        // Dev Remote Server Config
        //
        return {

            APP_ENGINE_SURVEY_SERVICES_URL:"https://api.ami-dev-services.com",
            // APP_ENGINE_SURVEY_SERVICES_URL:"https://ami-survey-services-dot-allergan-ami-d2-dev-6a857.uc.r.appspot.com",


            // FIREBASE_STORAGE_URL:"https://firebasestorage.googleapis.com/v0/b/allergan-ami-d2-dev-6a857.appspot.com/o/",
            // RECAPCTHA_KEY:"6Ldtna0aAAAAAPB4YZZnFp6evOIUnoqCeI88j6US",

            GTM_CODE:"GTM-WRLV2CG",

            FIREBASE_CONFIG: {
                apiKey: "AIzaSyBrXdvxv2Mm0lG9ej2FEQof0qd4_CAzWAs",
                authDomain: "allergan-ami-survey-dev.firebaseapp.com",
                projectId: "allergan-ami-survey-dev",
                storageBucket: "allergan-ami-survey-dev.appspot.com",
                messagingSenderId: "1011904675572",
                appId: "1:1011904675572:web:9d8eb1ba2ca18c4e8c68a1",
                measurementId: "G-K00HLE1NTR"
            }
        }
    }

    if(ENVIRONMENT===ENVIRONMENT_TYPES.PRODUCTION) {
        //
        // Production Config
        //
        return {

            APP_ENGINE_SURVEY_SERVICES_URL:"https://api.portal-prod-services.com",
            // APP_ENGINE_SURVEY_SERVICES_URL:"https://ami-survey-services-dot-allergan-ami-production.uc.r.appspot.com",

            // FIREBASE_STORAGE_URL:"https://firebasestorage.googleapis.com/v0/b/allergan-ami-production.appspot.com/o/",
            // RECAPCTHA_KEY:"6LeNg_QbAAAAAB45ktAccEsueYpJaoLD7k_3BLEG",

            GTM_CODE:"GTM-P8PJLJ5",

            FIREBASE_CONFIG: {
                apiKey: "AIzaSyD4Ecc6cgbnSUCOIfwbiII6mRHiEJyQKIw",
                authDomain: "allergan-ami-survey-production.firebaseapp.com",
                projectId: "allergan-ami-survey-production",
                storageBucket: "allergan-ami-survey-production.appspot.com",
                messagingSenderId: "1099394863044",
                appId: "1:1099394863044:web:46f7a8ddc576e654329e91",
                measurementId: "G-4GVS18E6TM"
            }
        }
    }


    if(ENVIRONMENT===ENVIRONMENT_TYPES.WARNING__LOCAL_PRODUCTION) {

        //
        // WARNING: ONLY USE FOR TROUBLESHOOTING PRODUCTION
        //
        return {

            APP_ENGINE_SURVEY_SERVICES_URL:"http://localhost:8089",
            // FIREBASE_STORAGE_URL:"https://firebasestorage.googleapis.com/v0/b/allergan-ami-production.appspot.com/o/",
            // RECAPCTHA_KEY:"6LeNg_QbAAAAAB45ktAccEsueYpJaoLD7k_3BLEG",

            GTM_CODE:"GTM-P8PJLJ5",

            FIREBASE_CONFIG: {
                apiKey: "AIzaSyD4Ecc6cgbnSUCOIfwbiII6mRHiEJyQKIw",
                authDomain: "allergan-ami-survey-production.firebaseapp.com",
                projectId: "allergan-ami-survey-production",
                storageBucket: "allergan-ami-survey-production.appspot.com",
                messagingSenderId: "1099394863044",
                appId: "1:1099394863044:web:46f7a8ddc576e654329e91",
                measurementId: "G-4GVS18E6TM"
            }
        }
    }
};