import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import firebase from 'firebase/app';
import "firebase/analytics";

const FirebaseAnalytics = (props) => {

    let location = useLocation();

    useEffect(() => {

        const page_path = location.pathname + location.search;

        // console.log(page_path)

        firebase.analytics().setCurrentScreen(page_path);

        // firebase.analytics().logEvent("screen_view", { screen_name:page_path });

        firebase.analytics().logEvent("page_view", { page_path });

        // firebase.analytics().logEvent("page_view", {
        //     page_path:page_path,
        //     page_title:page_path
        // });

    }, [location]);

    return null;
};


export default FirebaseAnalytics;