import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import './utils/config-axios';

import {BrowserRouter}  from 'react-router-dom';

import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {Provider} from 'react-redux';

import miscReducer from './store/reducers/misc';
import securityReducer from './store/reducers/security';
import videoReducer from './store/reducers/video';

import App from './App';

import ScrollToTop from './components/UI/ScrollToTop/ScrollToTop';

import TagManager from 'react-gtm-module';

// import ServiceWorkerWrapper from './components/ServiceWorkerWrapper/ServiceWorkerWrapper';
import FirebaseAnalytics from './components/Firebase/FirebaseAnalytics';
import {getSiteConfig} from './config';

import smoothscroll from './utils/smooth-scroll-polyfil';

//
// Firebase...
//
import firebase from "firebase/app";
import "firebase/analytics";
// import "firebase/auth";
// import "firebase/functions";
// import "firebase/firestore";

// import * as serviceWorker from './serviceWorker';

// import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/styles.scss';
// import './index.scss';


//
// Initialize the smooth scrolling polyfill.
// Used for window.scrollTo({'smooth'}) on Apple devices.
//
smoothscroll.polyfill();


//
// Initialize Firebase/Analytics
//
firebase.initializeApp(getSiteConfig().FIREBASE_CONFIG);
firebase.analytics();

// Dev Configuration...
if (window.location.hostname === "localhost") {

    console.log("Local Dev Mode");

    // firebase.firestore().useEmulator("localhost", 8085);
    // firebase.functions().useEmulator("localhost", 5001);
    // firebase.auth().useEmulator('http://localhost:9099/');
}


//
// Google Tag Manager
//
const tagManagerArgs = {
    gtmId: getSiteConfig().GTM_CODE
};

TagManager.initialize(tagManagerArgs);



//
// Setup Redux
//

const composeEnhancers = (process.env.NODE_ENV==='development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
// console.log(composeEnhancers);


const rootReducer = combineReducers({
    misc: miscReducer,
    security: securityReducer,
    video: videoReducer
});

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);



const app = (

        <Provider store={store}>
            <BrowserRouter>
                <ScrollToTop>
                    <App/>
                </ScrollToTop>
                <FirebaseAnalytics/>
            </BrowserRouter>
        </Provider>

);




ReactDOM.render(
    <React.Fragment>
        {app}
        {/*<ServiceWorkerWrapper/>*/}
    </React.Fragment>,
    document.getElementById('root')
);


// ReactDOM.render(
//     <React.StrictMode>
//         {app}
//         {/*<ServiceWorkerWrapper/>*/}
//     </React.StrictMode>,
//     document.getElementById('root')
// );


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

//
// NOTE: This has been moved to: /components/ServiceWorkerWrapper/ServiceWorkerWrapper.js

//
// const swConfig = {
//     onUpdate(registration){
//         console.log("onUpdate", registration);
//
//
//     },
//
//     onSuccess(registration){
//         console.log("onSuccess", registration);
//     }
// };
//
//
// //serviceWorker.unregister();
// serviceWorker.register(swConfig);
//

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
