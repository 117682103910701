import * as actionTypes from '../actions/actionTypes'

import {updateObject} from '../../utils/utility';


const initialState = {

    isVideoPlaying: false,
    playingVideoWistiaHashedId: null,
    playingVideoContentId: null,
};


const reducer = (state=initialState, action) => {

    switch(action.type){

        case actionTypes.SET_PLAYING_VIDEO:
            return updateObject(state, {
                isVideoPlaying:action.isVideoPlaying,
                playingVideoWistiaHashedId: action.playingVideoWistiaHashedId,
                playingVideoContentId: action.playingVideoContentId
            });



        default:
            return state;
    }
};

export default reducer;