import * as actionTypes from '../actions/actionTypes'

import {updateObject} from '../../utils/utility';


const initialState = {

    hasSystemError: false,

    profileImgUpdated:0
};


const reducer = (state=initialState, action) => {

    switch(action.type){

        case actionTypes.SET_HAS_SYSTEM_ERROR:
            return updateObject(state, {
                hasSystemError:action.hasSystemError
            });


        case actionTypes.SET_PROFILE_IMAGE_UPDATED:
            return updateObject(state, {
                profileImgUpdated:state.profileImgUpdated+1
            });

        default:
            return state;
    }
};

export default reducer;