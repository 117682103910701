import * as actionTypes from './actionTypes';

export const setSecurityContextRole = (role_id) => {
    return {
        type:actionTypes.SET_SECURITY_CONTEXT_ROLE,
        role_id: role_id
    }
};


export const setSecurityContextLoginMessage = (login_message, login_status) => {
    return {
        type:actionTypes.SET_SECURITY_LOGIN_MESSAGE,
        login_message: login_message,
        login_status: login_status
    }
};


export const setSecurityContext = (user) => {

    return {
        type:actionTypes.SET_SECURITY_CONTEXT,
        user_id: user.user_id,
        username: user.username,
        role_id: user.role_id,
        firstname: user.firstname,
        lastname: user.lastname,
        sales_team_role_type_array: user.sales_team_role_type_array
    }
};


export const clearSecurityContext = () => {
    return {
        type:actionTypes.CLEAR_SECURITY_CONTEXT
    }
};