import * as actionTypes from './actionTypes';

export const setHasSystemError = (hasSystemError) => {
    return {
        type:actionTypes.SET_HAS_SYSTEM_ERROR,
        hasSystemError: hasSystemError
    }
};



export const setProfileImageUpdated = () => {
    return {
        type:actionTypes.SET_PROFILE_IMAGE_UPDATED
    }
};

