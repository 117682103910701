import React from 'react';

import classes from './Spinner.module.css'

const spinner = (props) => {

    let centerClass = (props.inline)?"":classes.center;

    let label=null;
    let styles = {};
    if(props.label){
        label = <div className={classes.label}>{props.label}</div>;

        styles.marginBottom = "20px";
    }


    return (
        <div style={{height:"100%"}}>
            <div className={centerClass +" text-center"}>
                <div className={classes.Loader} style={styles}> </div>
                {label}
            </div>
        </div>
    );
};

export default spinner;