import firebase from 'firebase/app';
import 'firebase/auth';

import axios from "axios/index";
import {getSiteConfig} from "../config";



const defaultOptions = {
    baseURL: getSiteConfig().APP_ENGINE_SURVEY_SERVICES_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
    },
};



// Create instance
const instance = axios.create(defaultOptions);

// Set the AUTH token for any request
instance.interceptors.request.use( async (config) => {

    // console.log("AXIOS CONFIG",config);

    try{
        if(!firebase.auth().currentUser){
            return config;
        }

        const token = await firebase.auth().currentUser.getIdToken(false);

        if(token){
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    } catch(error){

        console.log("axios error in interceptor",error);

        return Promise.reject(error);
    }
});


export default instance;
